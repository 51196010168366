:root {
    --primay-color: #ff013c;
    --secondary-color: #00e6f6;
    --background-color: #f8f005;
    --shadow-color: #0036f6;
    --glitch-content-width: 380px;
    --glitch-content-height: 86px;
    --text-color: #ffffff;
    --text-size: 1rem;
    --text-content: 'AVENIR'
}

.glitch-effect::after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);

    content: var(--text-content);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, var(--secondary-color) 5%, var(--primay-color) 5%);
    text-shadow: -3px -3px 0px var(--background-color), 3px 3px 9px var(--shadow-color);
    clip-path: var(--slice-0);
}

.glitch-effect:hover::after {
    animation: 1s glitcher;
    animation-timing-function: steps(2, end);
}

@font-face {
    font-family: Cyber;
    src: url('https://assets.codepen.io/605876/Blender-Pro-Bold.otf');
    font-display: swap;

}

.glitchy-effect {
    position: relative;
    font-size: 30px;
}

.glitchy-effect::before {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);

    content: var(--text-content);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, var(--secondary-color) 5%, var(--primay-color) 5%);
    text-shadow: -3px -3px 0px var(--background-color), 3px 3px 9px var(--shadow-color);
    clip-path: var(--slice-0);
    animation: glitcher 1s infinite;
    animation-timing-function: steps(2, start);
}

@keyframes glitcher {
    0% {
        clip-path: var(--slice-1);
        transform: translate(-2px, -10px);
    }

    10% {
        clip-path: var(--slice-3);
        transform: translate(10px 10px);
    }

    20% {
        clip-path: var(--slice-1);
        transform: translate(-10px, 10px);
    }

    30% {
        clip-path: var(--slice-3);
        transform: translate(0px, 5px);
    }

    40% {
        clip-path: var(--slice-2);
        transform: translate(-5px, 0px);
    }

    50% {
        clip-path: var(--slice-3);
        transform: translate(5px, 0px);
    }

    60% {
        clip-path: var(--slice-4);
        transform: translate(5px, 10px);
    }

    70% {
        clip-path: var(--slice-2);
        transform: translate(-10px, 10px);
    }

    80% {
        clip-path: var(--slice-5);
        transform: translate(20px, -10px);
    }

    90% {
        clip-path: var(--slice-1);
        transform: translate(-10px, 0px);
    }

    100% {
        clip-path: var(--slice-1);
        transform: translate(0);
    }
}